<template>
<div>
  <v-tabs
      v-model="selected"
      color="transparent"
      active-class="activeTab"
      class="recap-tabs"
  >
    <v-tabs-slider color="accent"></v-tabs-slider>

    <v-tab
        v-for="item in steps"
        :key="item"
        :disabled="checkIfStepIsCompleted(item)"
    >
      {{ item }}
    </v-tab>
  </v-tabs>
  <v-tabs-items class="transparent" v-model="selected">
    <v-tab-item key="1 von 7">
      <One recap/>
    </v-tab-item>
    <v-tab-item key="2 von 7">
      <Two recap/>
    </v-tab-item>
    <v-tab-item key="3 von 7">
      <Three recap/>
    </v-tab-item>
    <v-tab-item key="4 von 7">
      <Four recap/>
    </v-tab-item>
    <v-tab-item key="5 von 7">
      <Five recap/>
    </v-tab-item>
    <v-tab-item key="6 von 7">
      <Six recap/>
    </v-tab-item>
    <v-tab-item key="7 von 7">
      <Seven recap/>
    </v-tab-item>
  </v-tabs-items>

</div>
</template>

<script>
import Two from "@/views/Prozesse/OnBoarding/Steps/Two";
import Three from "@/views/Prozesse/OnBoarding/Steps/Three";
import Four from "@/views/Prozesse/OnBoarding/Steps/Four";
import Five from "@/views/Prozesse/OnBoarding/Steps/Five";
import Six from "@/views/Prozesse/OnBoarding/Steps/Six.vue";
import Seven from "@/views/Prozesse/OnBoarding/Steps/Seven.vue";
import One from "@/views/Prozesse/OnBoarding/Steps/One.vue";
export default {
  name: "OnBoardingRecap",
  components: {One, Seven, Six, Five, Four, Three, Two},
  data(){
    return{
      steps:["1 von 7","2 von 7","3 von 7","4 von 7","5 von 7","6 von 7", "7 von 7"],
      selected:"1 von 7",
    }
  },
  methods:{
    checkIfStepIsCompleted(item){
      console.log(item.substring(0,1));
      console.log(this.$store.state.processes.onboarding.currentStep)
      return item.substring(0,1)*1>this.$store.state.processes.onboarding.currentStep;
    }
  }
}
</script>

<style scoped>

</style>