<template>
  <v-dialog
      v-model="$store.state.processes.onboarding.showFinishedDialog"
      width="500"
  >
    <v-card>
      <v-card-title class="text-h6 primary text-secondary">
        Onboarding abgeschlossen!
      </v-card-title>

      <v-card-text class="py-3">
      <p>Vielen Dank, mit deinen Angaben hilfst du uns, dein Projekt effizient & mit bester Qualität abzuschließen.</p>
      <p>Auf dieser Seite kannst du nun den Projektstand jederzeit nachvollziehen.</p>
        <p>Falls du Fragen hast, findest du dort auch deine direkten Anprechpartner.</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="$store.state.processes.onboarding.showFinishedDialog = false"
        >
          Let's go
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>


export default {
  name: "SuccessDialog",
  watch:{
    '$store.state.processes.onboarding.showFinishedDialog'(){
      if(this.$store.state.processes.onboarding.showFinishedDialog)this.start();
    }
  },
  methods:{
    created(){

    },
    start() {
      this.$confetti.start();
      setTimeout(()=>{this.stop()},10000)
    },

    stop() {
      this.$confetti.stop();
    },
  }
}
</script>

<style scoped>

</style>