<template>
<v-app-bar flat app max-height="60px" style="position: relative" class="mobile-bar">
  <v-progress-linear v-if="$route.name==='Welcome'" style="position:absolute;left:0;top:0;width:100%;z-index:99999" color="accent" v-model="progress"></v-progress-linear>
      <v-btn class="d-flex flex-wrap two-line-btn" icon :disabled="$route.name==='Prozesse'" @click="$router.push('/processes/onboarding')"><v-icon>mdi-bookmark-multiple</v-icon><small>Prozesse</small></v-btn>
      <v-spacer></v-spacer>
      <v-btn class="d-flex flex-wrap two-line-btn" icon :disabled="$route.name==='Projects'" @click="$router.push('/my/projects')"><v-icon>mdi-folder</v-icon><br/><small>Projekte</small></v-btn>
      <v-spacer></v-spacer>
      <v-btn class="d-flex flex-wrap two-line-btn" icon :disabled="$route.name==='Service'" @click="$router.push('/service')"><v-icon>mdi-wrench</v-icon><small>Service</small></v-btn>
      <v-spacer></v-spacer>
      <v-btn class="d-flex flex-wrap two-line-btn" :disabled="$route.name==='Profile'" @click="$router.push('/profile')" v-if="!currentUser" icon><v-icon>mdi-account</v-icon></v-btn>
      <v-btn class="mb-1" :disabled="$route.name==='Profile'" @click="$router.push('/profile')" v-else icon><v-avatar size="40px"><v-img :src="currentUser.profilePic?'data:image/png;base64,'+currentUser.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"
      ></v-img></v-avatar></v-btn>
  <v-spacer v-if="isAdmin"></v-spacer>
  <v-btn v-if="isAdmin" class="d-flex flex-wrap two-line-btn" icon :disabled="$route.name==='Admin'" @click="$router.push('/admin')"><v-icon>mdi-shield</v-icon><small>Admin</small></v-btn>


</v-app-bar>
</template>

<script>
export default {
  name: "Mobilebar",
  methods:{
    handleLogout:function(){
      this.$store.dispatch('auth/logout')
      this.$router.push("/")
    },
  },
  computed:{
      isAdmin(){
        return this.$store.state.auth.user && this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
      },
    page:{
      get() {
        return this.$store.state.app.welcome.page
      },
      set(value){
        this.$store.state.app.welcome.page=value;
      }
    },
    pages(){
      return this.$store.state.app.welcome.pages
    },
    progress(){
      return ((this.page)/(this.pages))*100;
    },
    currentUser(){
      return this.$store.state.auth.user;
    }
  }
}
</script>

<style scoped>
.scroll-container {
  overflow: auto;
  -webkit-scroll-snap-type: y mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
}

.scroll-area {
  scroll-snap-align: start;
}
</style>