<template>
  <v-container>

    <VideoWrapper :thumbnail="require('@/assets/onboardingvideos/Thumbnail7.png')" :video-url="require('@/assets/onboardingvideos/Teil 7.webm')" title="Abschließende Worte und Kickoff-Termin"></VideoWrapper>
    <!-- Calendly Inline-Widget Beginn -->
    <div>
    <vue-calendly url="https://calendly.com/marius-heilmeier/onboarding" :height="600"></vue-calendly>
    </div>
    <div v-if="!recap" style="position: absolute;bottom:0;left:0;width:100%" class="px-5 my-5 d-flex justify-space-between">
      <v-btn text @click="stepBack">Zurück</v-btn><v-btn color="primary" @click="checkStep">Onboarding abschließen*</v-btn>
      <small style="width:100%">*Nach Abschluss des Onboardings können die Angaben jederzeit angesehen aber nichtmehr geändert werden. </small>
    </div>

  </v-container>
</template>
<script>
import {getBack, nextStep} from "@/services/onboarding.service";
import ApiService from "@/services/api.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";

export default {
  name: "Seven",
  components: {VideoWrapper},
  props:{
    recap:Boolean
  },
  methods:{
    stepBack(){
      getBack();
    },
    checkStep(){
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep().then(() => {

        this.$store.dispatch("project/getMyProjects").then(() =>{
          ApiService.post("onboarding/project/get",{id:this.$store.state.processes.onboarding.instanceId}).then(response => {
            let project=response.data;
            let projects= this.$store.state.project.my;
            this.$store.state.project.selected=projects[projects.indexOf(x => x.id === project.id)]
          });
          this.$store.state.processes.onboarding.showFinishedDialog=true;
          this.$router.push("/my/projects");
        });
        });

    }
  }
}
</script>

<style scoped>

</style>