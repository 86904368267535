<template>
  <v-app id="app" style="overflow-x:hidden">


    <!-- IOS PWA INSTALL PROMPT  -->
    <PWAPrompt :permanentlyHideOnDismiss="false"/>
    <Topbar/>
<v-main class="" style="position: relative;padding:60px 0 68px">
  <!-- ANDROID PWA INSTALL PROMPT  -->

  <v-banner
      color="info"
      v-if="deferredPrompt"
      style="position:absolute;top:0;left:0;width:100vw;z-index:9999"
      dark
      class="text-left"
  >

    Installiere jetzt unsere App, um das Elephant Kundenportal optimal zu nutzen.

    <template v-slot:actions>
      <v-btn text @click="dismiss">Nein, danke!</v-btn>
      <v-btn text @click="install">Installieren</v-btn>
    </template>
  </v-banner>
  <v-img width="250px" :src="require('./assets/ElephantNew.png')" style="pointer-events: none;position: absolute;bottom:0;right: -100px;opacity: 0.02"></v-img>
    <v-layout class="mx-xs-0" fill-height>
      <!--<v-flex xs2 class="d-none d-md-block pr-2">
        <Sidebar/>
      </v-flex>-->
    <v-flex xs12>
    <v-container class="pa-0" fluid :class="{'transparent':isMobile}" height="100%">
      <router-view @scrollTop="scrollTop"/>
    </v-container>
    </v-flex >
    </v-layout>


  <v-layout column wrap style="position:fixed;bottom:1em;right:1em;z-index:999">

    <v-alert min-width="200px" v-for="(notification,index) of this.$store.state.notificationsStore.notifications"
             :dark="!notification.type"
             :key="index"
             dismissible
             style="z-index:10"
             class="animate__animated animate__backInUp"
             :class="{'animate__fadeOutRight' : notification.animation,'accent':!notification.type}"
             :type="notification.type"
    >
      <v-row align="center">
        <v-col class="grow">
          <span v-html="notification.text"></span>
          <div v-if="notification.subtext">
            <small v-html="notification.subtext"></small></div>
        </v-col>
        <v-col v-if="notification.action" class="shrink">
          <v-btn color="secondary" class="text-primary" small @click="$router.push(notification.action)"><span v-html="notification.buttonText"></span></v-btn>
        </v-col>
      </v-row>



    </v-alert>
  </v-layout>

  <v-dialog
      v-if="currentUser"
      v-model="currentUser.changePasswordOnNextLogin"
      persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        Bitte ändere dein Passwort.
      </v-card-title>
      <v-card-text>
        <v-container>
        <p>Mit diesen Daten kannst du dich nun jederzeit im Elephant Kundenportal einloggen.</p>
       <v-form v-model="valid">
        <v-text-field label="EMail" placeholder="Email" disabled v-model="currentUser.email"></v-text-field>

          <v-text-field label="Neues Passwort" placeholder="Neues Passwort" type="password" :rules="passwordRules" v-model="newPassword"></v-text-field>
          <v-checkbox v-model="acceptAGB" label="Ich akzepiere dass Elephant meine Daten speichert, sowie die AGB & Datenschutz"></v-checkbox>
       </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            :disabled="!valid || !acceptAGB"
            @click="changePassword"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
<SuccessDialog/>
</v-main>
    <v-footer app>
    <Mobilebar class="hidden-lg-and-up"/>
    </v-footer>
  </v-app>
</template>

<script>
import Topbar from "@/views/Navigation/Topbar";
import Mobilebar from "@/views/Navigation/Mobilebar";
import PWAPrompt from 'vue2-ios-pwa-prompt';
import {changePassword} from "@/services/user.service";
import SuccessDialog from "@/views/Prozesse/OnBoarding/SuccessDialog";
//TODO Add Frontend to see Processes and Projects; And remove old DME STuff
export default {
  name: 'App',
  components: {SuccessDialog, Mobilebar, Topbar, PWAPrompt},
  data(){
    return{
      acceptAGB:false,
      heightNav:null,
      deferredPrompt:null,
      newPassword:"",
      valid:false,
      passwordRules:
          [
            v => !!v || this.$t('login.passwordRequired'),
            value => {
              const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
              return (
                  pattern.test(value) ||
                  "Min. 8 characters with at least one capital letter, a number and a special character."
              )}
          ]
    }
  },

  created() {
    this.installPWA();
    //this.loadContacts();
    //if(this.currentUser) this.registerFirebase();
  },
  computed:{
    isMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        return true
      }else{
        // false for not mobile device
        return false
      }
    },
  currentUser(){
    return this.$store.state.auth.user;
  },
    dark(){
      return this.$vuetify.theme.dark;
    }
  },
  mounted () {

    this.$store.state.app.initialHistory=window.history.length;
  },
  methods:{
    changePassword(){
      console.log("Change PW",this.newPassword);
      changePassword(this.newPassword);

      //this.currentUser.changePasswordOnNextLogin=false;
    },
    scrollTop(){
      this.$nextTick(() => {
        console.log("Scrolling top",this.$refs.mainContainer)
        this.$refs.mainContent.scrollTop = 0;
      });
    },
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    installPWA(){
      console.log("PWA Install?")
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        console.log("Prompt "+e);
      });
      window.addEventListener("appinstalled", () => {
        console.log("app installed");
        this.deferredPrompt = null;
      });

    },

    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
}
</script>

<style>
#mainContainer{
  min-height: 100vh;
}
@media screen and (max-width: 600px) {
  #mainContainer{
    margin-top:60px;
    min-height: 100vh;

  }
}


</style>
