<template>
<v-container>

  <VideoWrapper :thumbnail="require('@/assets/onboardingvideos/Thumbnail1.png')" title="Willkommen!" :video-url="require('@/assets/onboardingvideos/Teil 1.webm')">

  </VideoWrapper>

  <p class="">
    Herzlich Willkommen!<br/>
    Wir freuen uns sehr auf unsere Zusammenarbeit und gemeinsame Reise. Dieses Onboarding besteht aus einer <b>Videoreihe</b>, in der unser Co-Founder Jonah alle relevanten Punkte unserer Zusammenarbeit klärt. Wir fragen Ihre Wünsche, Anforderungen und sonstige Details ab. So können wir Ihnen bestmöglich zur Seite stehen.
<br/>
    <b>Viel Erfolg!</b>

  </p>
  <v-spacer></v-spacer>
      <div v-if="!recap" style="position: absolute;bottom:0;width:100%;left:0;" class="px-5 my-5 d-flex justify-space-between">
    <v-btn text disabled>Zurück</v-btn><v-btn color="primary" @click="checkStep">Nächster Schritt</v-btn>
  </div>
  </v-container>
</template>

<script>
import {nextStep} from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";

export default {
  name: "One",
  components: {VideoWrapper},
  props:{
    recap:Boolean
  },
  methods:{
    checkStep(){
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep().then(() => {
        this.$emit("next");
      })
      }
  }
}
</script>

<style scoped>

</style>