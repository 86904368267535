<template>
  <div class="gradient-background">
  <v-container class="py-0" style="max-width: 800px;">
  <router-view v-if="$store.state.processes.onboarding.currentStep!=-1" @next="getCurrentStep"/>
  </v-container>
  </div>
</template>

<script>
import {NotifiyClient} from "@/models/notification";
export default {
  name: "OnBoardingWrapper",
  props:{
    id:String
  },
  created() {
    if(!this.id || this.id ===""){
      this.$store.dispatch("notificationsStore/add",new NotifiyClient("error","No Process Instance",false,"Please reenter the process on your userprofile or over the button in our Email",""))
    }else{
      this.getCurrentStep()
    }

  },
  watch:{
    '$store.state.processes.onboarding.currentStep'() {
      switch (this.$store.state.processes.onboarding.currentStep){
        case 0: console.log("I am step 0"); break;
        case 1: this.$router.push("/processes/onboarding/"+this.$store.state.processes.onboarding.instanceId+"/1of7");break;
        case 2: this.$router.push("/processes/onboarding/"+this.$store.state.processes.onboarding.instanceId+"/2of7");break;
        case 3: this.$router.push("/processes/onboarding/"+this.$store.state.processes.onboarding.instanceId+"/3of7");break;
        case 4: this.$router.push("/processes/onboarding/"+this.$store.state.processes.onboarding.instanceId+"/4of7");break;
        case 5: this.$router.push("/processes/onboarding/"+this.$store.state.processes.onboarding.instanceId+"/5of7");break;
        case 6: this.$router.push("/processes/onboarding/"+this.$store.state.processes.onboarding.instanceId+"/6of7");break;
        case 7: this.$router.push("/processes/onboarding/"+this.$store.state.processes.onboarding.instanceId+"/7of7");break;

        default:this.$router.push("/processes/onboarding/"+this.$store.state.processes.onboarding.instanceId);
      }
    }
  },
  methods:{
    getCurrentStep(){
      this.$store.dispatch("processes/continueOnboarding",this.id);
    }
  }
}
</script>

<style scoped>

</style>