<template>
  <div class="">
<v-app-bar dark app style="height: 60px" color="secondary lighten-1" flat class="rounded-md">

  <v-btn icon @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
 <div style="height:35px;width:35px">
   <v-img v-if="!$vuetify.theme.dark" height="35px" width="35px" :src="require('@/assets/ElephantNew.png')"></v-img>
   <v-img v-else height="35px" width="35px" :src="require('@/assets/ElephantNew.png')"></v-img>
 </div>

  <v-spacer></v-spacer>
  <div v-if="$route.name=='Projects' && $store.state.project.my">
      <v-select background-color="secondary lighten-1" no-data-text="Kein Projekt gefunden." style="font-size: 1.2rem" hide-details flat :menu-props="{ auto: true, overflowX: true }" class="shrink px-5 font-weight-bold" solo :items="$store.state.project.my" return-object item-text="name" v-model="$store.state.project.selected"></v-select>
  </div>
  <v-app-bar-title style="margin-left:-20px;min-width: 35vw" class=" font-weight-bold" v-else v-html="$route.name"></v-app-bar-title>

  <v-spacer class="hidden-md-and-down"></v-spacer>
  <v-btn class="hidden-md-and-down" text :disabled="$route.name==='Projects'" @click="$router.push('/my/projects')"><v-icon>mdi-folder</v-icon><br/><span>Meine Projekte</span></v-btn>

  <v-spacer class="hidden-md-and-down"></v-spacer>
  <div class="hidden-md-and-down">
  <v-btn v-if="isAdmin" class="d-flex flex-wrap two-line-btn " icon :disabled="$route.name==='Admin'" @click="$router.push('/admin')"><v-icon>mdi-shield</v-icon><small>Admin</small></v-btn>

  </div>
  <v-spacer class="hidden-md-and-down" v-if="isAdmin"></v-spacer>
    <v-btn class="hidden-md-and-down" :disabled="$route.name==='Profile'" @click="$router.push('/profile')" v-if="!currentUser" icon><v-icon>mdi-account</v-icon></v-btn>
  <v-btn class="mb-1 hidden-md-and-down" :disabled="$route.name==='Profile'" @click="$router.push('/profile')" v-else icon><v-avatar size="40px"><v-img :src="currentUser.profilePic?'data:image/png;base64,'+currentUser.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"
  ></v-img></v-avatar></v-btn>

  <v-spacer></v-spacer>

  <div>
    <v-slide-y-transition>
    <v-tooltip v-if="!$vuetify.theme.dark" bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="elevation-0" color="transparent" small fab @click="darkMode">
          <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
        </v-btn>
      </template>
      <span>Dark Mode On</span>
    </v-tooltip>

    <v-tooltip v-else bottom>
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" class="elevation-0" color="transparent" icon @click="darkMode">
          <v-icon class="mr-1" color="yellow">mdi-white-balance-sunny</v-icon>
        </v-btn>
      </template>
      <span>Dark Mode Off</span>
    </v-tooltip>
    </v-slide-y-transition>
  </div>

  <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

</v-app-bar>
    <v-progress-linear style="position:fixed;z-index:9999;left:0;top:0;" v-if="$store.state.loading.loadingState || $store.state.loading.success.state"
                       :indeterminate="!$store.state.loading.success.state"
                       class="animate__animated"      :class="{'animate__fadeOutUp':$store.state.loading.success.animateOut,'bg-success':$store.state.loading.success.state=='success','bg-error':$store.state.loading.success.state=='error'}"
                       color="accent"
    ></v-progress-linear>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        right
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            active-class="primary--text text--accent-4"
        >
          <v-list-item v-for="navItem in $store.state.app.navItems" :key="navItem.title" @click="gotTo(navItem.target)">
            <v-list-item-title class="text-uppercase text-right">{{navItem.title}}</v-list-item-title>
          </v-list-item>

        <v-spacer style="height:25px"></v-spacer>
<div class="d-flex justify-end">
            <v-btn text plain @click="handleLogout" class="align-self-end text-right"><v-icon small >mdi-logout</v-icon> Log out</v-btn>

</div>
        </v-list-item-group>

      </v-list>



    </v-navigation-drawer>
  </div>
</template>


<script>
export default {
  name: "Topbar",
  data(){
    return{
      drawer:false
    }
  },

  computed:{
    isAdmin(){
      return this.$store.state.auth.user && this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    chatPartners(){
      return this.$store.state.chat.currentChatPartners
    },
    unreadMessages(){
      const sum = this.chatPartners.reduce((accumulator, object) => {
        return accumulator + object.newMessages;
      }, 0);
      return sum;
    }
  },
  methods:{
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    handleLogout:function(){
      this.$store.dispatch('auth/logout')
      this.$router.push("/")
    },
    gotTo(target){
      this.$router.push("/"+target);
    },
  }
}
</script>

<style scoped>

</style>