import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import {loading} from './loading.module';
import {notificationsStore} from "@/store/notifications.module";
import {processes} from "@/store/processes.module";
import {app} from "./app.module"
import {project} from "@/store/project.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        loading,
        processes,
        notificationsStore,
        app,
        project
    }
});