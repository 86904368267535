<template>
  <div>
    <h4 class="ml-1 mt-10"><span v-html="title"></span>
      <v-tooltip allow-overflow bottom v-if="help">
        <template v-slot:activator="{ on,attrs }">
          <v-icon class="mb-5" small v-on="on" v-bind="attrs">mdi-help-box</v-icon>
        </template>
        <span v-html="help"></span>
      </v-tooltip>
    </h4>
  </div>
</template>

<script>
export default {
  name: "FormSubheader",
  props:{
    title:String,
    help:String,
  }
}
</script>

<style scoped>

</style>