import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)
 import de from "vuetify/lib/locale/de";
import en from "vuetify/lib/locale/en";
const opts = {
    lang: {
        locales: { de,en },
        current: 'en',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#121E39',
                secondary: '#0A101F',
                accent: '#5445FF',
                text: '#F2F2F2',
                background:'#FFFFFF'
            },
            dark: {
            primary: '#FFFFFF',
            secondary: '#0A101F',
            background: '#1e1e1e'
                            },
        },
    }

}



export default new Vuetify(opts)