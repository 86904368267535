import { render, staticRenderFns } from "./Two.vue?vue&type=template&id=6daa9b24&scoped=true&"
import script from "./Two.vue?vue&type=script&lang=js&"
export * from "./Two.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6daa9b24",
  null
  
)

export default component.exports