<template>
  <v-timeline-item
      color="pink"
      small
  >
    <v-row class="pt-1">
      <v-col cols="3">
        <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="true"
            transition="scale-transition"
            left
            max-width="290px"
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
            <strong v-if="!update.timestamp">Datum</strong>
            <strong v-else v-html="update.timestamp"></strong>
            </div>
          </template>
          <v-date-picker
              v-model="update.timestamp"
              no-title
              @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <strong @input="onInputTitle" contenteditable="true">Title</strong>
        <div class="text-caption">
         <div contenteditable="true" @input="onInputContent">Content</div>
        </div>
        <v-btn class="mt-5" small @click="publishUpdate">Publish</v-btn>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
import ApiService from "@/services/api.service";
export default {
  name: "UpdateNew",
  props:{
    projectId:Number
  },
  data(){
    return{
      menu1:false,
      update:{
        timestamp:"",
        title:"",
        content:""
      }
    }
  },
  methods:{
    onInputTitle(e) {
      this.update.title=e.target.innerText;
    },
    onInputContent(e){
      this.update.content=e.target.innerText;
    },
    publishUpdate(){
      ApiService.post("project/update/publish",{projectId:this.projectId,updateItem:this.update}).then(response =>{
        console.log(response.data);
        this.$emit("updateProject",response.data);
      });
    }
  }
}
</script>

<style scoped>

</style>