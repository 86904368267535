import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import ConfirmationToken from "@/views/ConfirmationToken";
import RegisterVerification from "@/views/RegisterVerification";
import Logout from "@/views/Logout";
import store from '@/store'
import OnBoardingWrapper from "@/views/Prozesse/OnBoarding/OnBoardingWrapper";
import One from "@/views/Prozesse/OnBoarding/Steps/One";
import AdminHome from "@/views/Admin/AdminHome";
import Start from "@/views/Prozesse/OnBoarding/Steps/Start";
import Two from "@/views/Prozesse/OnBoarding/Steps/Two";
import Three from "@/views/Prozesse/OnBoarding/Steps/Three";
import Four from "@/views/Prozesse/OnBoarding/Steps/Four";
import Five from "@/views/Prozesse/OnBoarding/Steps/Five";
import Six from "@/views/Prozesse/OnBoarding/Steps/Six";
import Seven from "@/views/Prozesse/OnBoarding/Steps/Seven";
import OnboardingOverview from "@/views/Prozesse/OnBoarding/OnboardingOverview";
import ProjectWrapper from "@/views/Projekte/ProjectWrapper";
import OnBoardingRecap from "@/views/Prozesse/OnBoarding/OnBoardingRecap";
import AdminOnboardingList from "@/views/Admin/AdminOnboardingList";
import AdminProjectList from "@/views/Admin/AdminProjectList";
import ProjectItem from "@/views/Projekte/ProjectItem";
import ServiceWrapper from "@/views/Service/ServiceWrapper";

Vue.use(Router);

function guardMyRoute(to,from,next){
    let isAuthenticated = store.state.auth.status.loggedIn;
    if (to.name !== 'Login' && !isAuthenticated) {
        next('/login');
    }
    else{
        next();
    }
}

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path:"/admin",
            component:AdminHome,
        },
        {
            path:"/admin/onboardings",
            name:"Onboardings",
            component: AdminOnboardingList
        },
        {
            path:"/service",
            name:"Service",
            component: ServiceWrapper
        },
        {
            path:"/admin/projekte",
            name:"Projekte",
            component: AdminProjectList
        },
        {
            props:true,
            path:"/admin/projekte/:id",
            component:ProjectItem
        },
        {
            path:"/processes/onboarding",
            component:OnboardingOverview,
            name:"Prozesse"
        },{
            path:"/my/projects",
            component:ProjectWrapper,
            name:"Projects"
        },
        {
            path:"/processes/onboarding/:id",
            component:OnBoardingWrapper,
            props: true,
            children: [
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: 'start',
                    name:"Willkommen",
                    component: Start,
                    props: route => ({ user: route.query.q,email:route.query.a })

                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '/',
                    name:"Überblick",
                    component: OnBoardingRecap,
                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '1of7',
                    name:"Step 1 of 7",
                    component: One,

                },
                {
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '2of7',
                    name:"Step 2 of 7",
                    component: Two,

                },{
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '3of7',
                    name:"Step 3 of 7",
                    component: Three,

                },{
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '4of7',
                    name:"Step 4 of 7",
                    component: Four,

                },{
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '5of7',
                    name:"Step 5 of 7",
                    component: Five,

                },{
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '6of7',
                    name:"Step 6 of 7",
                    component: Six,

                },{
                    // UserProfile will be rendered inside User's <router-view>
                    // when /user/:id/profile is matched
                    path: '7of7',
                    name:"Step 7 of 7",
                    component: Seven,

                }
            ],
        },
        {
            path: '/',
            name: 'Home',
            beforeEnter:guardMyRoute,
            component: Login
        },

        {
            path: '/login',
            component: Login,
            name:'Login'
        },

        {
            path: '/logout',
            beforeEnter:guardMyRoute,
            component: Logout
        },

        {
            path: '/register',
            component: Register
        },
        {
            path: '/profile',
            beforeEnter:guardMyRoute,
            name: 'Profile',
            // lazy-loaded
            component: () => import('./views/Profile.vue')
        },

        {
            path: '/confirm-account',
            beforeEnter:guardMyRoute,
            name: 'Account Verifizierung',
            component: ConfirmationToken
        },
        {
            path: '/successful-registration',
            beforeEnter:guardMyRoute,
            name: 'Registrierung erfolgreich',
            component: RegisterVerification
        },
    ]
});