<template>
<div>

    <v-tabs
        dark
        background-color="secondary lighten-1"
        v-model="$store.state.app.tabs.selected"

    >
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tab
          v-for="item in $store.state.app.tabs.tabItems"
          :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
  <v-tabs-items v-model="$store.state.app.tabs.selected">
    <v-tab-item key="'Laufend'">
      <v-container flat>
        <v-list v-if="running.length>0">
          <OnboardingItem v-for="item of running" :key="item.id" :onboarding="item"></OnboardingItem>
        </v-list>
        <v-card-subtitle v-else>
          Keine Prozesse laufend.
        </v-card-subtitle>
      </v-container>
    </v-tab-item>
    <v-tab-item key="'Abgeschlossen'">
      <v-container flat>
        <v-list v-if="finished.length>0">
          <OnboardingItem v-for="item of finished" :key="item.id" :onboarding="item"></OnboardingItem>

        </v-list>
        <v-card-subtitle v-else>
          Keine Prozesse abgeschlossen.
        </v-card-subtitle>
      </v-container>
    </v-tab-item>
  </v-tabs-items>


</div>
</template>

<script>
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem";
export default {
  name: "OnboardingOverview",
  components: {OnboardingItem},
  created() {
    this.$store.dispatch("processes/getMyOnboardings");
  },
  computed:{
    running(){
      if(this.$store.state.processes.onboarding.data) return this.$store.state.processes.onboarding.data.filter(x=> x.activeStep<=7);
      return [];
      },
    finished(){
      if(this.$store.state.processes.onboarding.data) return this.$store.state.processes.onboarding.data.filter(x=> x.activeStep>7);
      return [];
    }
  },
  methods:{

  }
}
</script>

<style scoped>

</style>