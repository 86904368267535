<template>
  <v-card
      class="mx-auto"
      v-if="user"
      flat
  >
    <v-list-item two-line>
      <v-list-item-content>
        <div v-html="overHeadline" class="text-overline">

        </div>
        <v-list-item-title v-html="user.firstName+' '+user.lastName" class="text-h5 mb-1 text-capitalize">

        </v-list-item-title>
        <div>
        <v-btn
            rounded
            icon
            :href="'mailto:'+user.email"
        >
          <v-icon small>mdi-email</v-icon>
        </v-btn>
        </div>
        </v-list-item-content>

      <v-list-item-avatar
          rounded
          size="80"
          color="grey"
      >
        <v-img
            height="100%"
            width="100%"
            :src="user.profilePic?'data:image/png;base64,'+user.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"
        />
      </v-list-item-avatar>
    </v-list-item>




  </v-card>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "ContactItem",
  data(){
    return{
    user:null
    }
  },
  props:{
    overHeadline:String,
    userId:Number
  },
  created(){
    this.getUserById(this.userId);
  },
  methods:{
    getUserById(id){
      let self=this;
      ApiService.get("user/get?id="+id).then(response => {
        self.user=response.data;
      })
    },
  },
}
</script>

<style scoped>

</style>