<template>
  <v-container>
    <VideoWrapper :thumbnail="require('@/assets/onboardingvideos/Thumbnail4.png')" title="Datei-Uploads & Tracking mit Google Analytics" :video-url="require('@/assets/onboardingvideos/Teil 4.webm')"/>
    <!--TODO  Link zu Schnittstelle -> SchnittstelleURL attribut in Projekt -->

    <v-sheet class="py-4 px-8 rounded-elephant" elevation="2">
      <h4>Wir haben eine Schnittstelle für Sie vorbereitet:</h4>
      <p>Diese dient als <i>Datei-Austauschplattform</i> zwischen Ihnen und Elephant für die <i>gesamte Projektlaufzeit.</i></p>
      <p>Bitte laden Sie dort alle <b>nötigen Dateien</b> hoch. Dazu zählen <b>Logo</b>, Bilder die wir für Sie auf der neuen Website verwenden sollen, fertige oder aber auch grobe Texte, die Elephant als Inspiration zur Texterstellung nutzen kann.</p>
      <p>Natürlich können Sie un s auch jegliche andere Dateien bereitstellen.</p>
      <v-btn block class="rounded-elephant" color="primary" @click="zurSchnittstelle">Zur Schnittstelle</v-btn>
    </v-sheet>

    <v-form
        class="my-5 mx-2"
        ref="form"
        v-model="valid"
    >
      <FormSubheader title="Tracking Codes" help="Bitte teilen Sie uns Ihre Trackingcodes mit, welche auf der alten Website genutzt werden, oder in Zukunft genutzt werden soll."></FormSubheader>
      <InputGroup>
        <v-select hide-details filled rounded :items="gaProperty" label="Bitte wählen Sie passend aus:" v-model="form.gaProperty"></v-select>

        <v-text-field class="mt-1" hide-details filled rounded label="Google Analytics Tracking Code" v-model="form.gaTracking"></v-text-field>
      <v-text-field class="mt-1" hide-details filled rounded label="LinkedIn Tracking Code" v-model="form.linkedInTracking"></v-text-field>
      <v-text-field  class="mt-1" hide-details filled rounded label="Facebook Pixel Tracking Code" v-model="form.pixelTracking"></v-text-field>
      <v-text-field class="mt-1" hide-details filled rounded label="Weitere Tracking Codes" hint="Bitte mit Komma trennen." v-model="form.extraTracking"></v-text-field>
     </InputGroup>
      <div v-if="!recap" class="mx-5 my-5 d-flex justify-space-between">
        <v-btn @click="stepBack" text>Zurück</v-btn>
        <v-btn @click="saveStepData" text>Zwischenspeichern</v-btn>
        <v-btn color="primary" :disabled="!valid" :loading="$store.state.loading.loadingState" @click="checkStep">Nächster Schritt</v-btn>
      </div>

    </v-form>
  </v-container>
</template>

<script>
import {getBack, getOnboardingData, nextStep, saveData} from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";
import FormSubheader from "@/views/Prozesse/OnBoarding/Steps/FormSubheader";
import InputGroup from "@/views/Prozesse/OnBoarding/Steps/InputGroup";
import ApiService from "@/services/api.service";
export default {
  name: "Four",
  components: {InputGroup, FormSubheader, VideoWrapper},
  props:{
    recap:Boolean
  },
  data(){
    return{
      schnittstellenId:null,
      valid:false,
      gaProperty:[
          "Ich habe keine GA Property & will, dass Elephant eine erstellt.",
          "Ich habe keine GA Property, werde diese selbst erstellen und ELephant als Bearbeiter freigeben.",
          "Ich habe bereits eine GA Property & werde Elephant als Bearbeiter freigeben.",
          "Ich möchte kein Google Analytics nutzen."
      ]
    }
  },
  computed:{
    form(){
      return this.$store.state.processes.onboarding.data;
    }
  },
  created() {
    getOnboardingData().then(()=>{
      this.getSchnittstellenId();
    });

  },
  methods:{
    stepBack(){
      getBack();
    },
    saveStepData(){
      saveData(this.$store.state.processes.onboarding.data);
    },
    zurSchnittstelle(){
      window.open('https://drive.google.com/drive/u/0/folders/'+this.schnittstellenId+'#grid')
    },
    getSchnittstellenId(){
      let self=this;
      ApiService.post("onboarding/project/get",{id:this.$store.state.processes.onboarding.instanceId}).then(response => {
        self.schnittstellenId=response.data.schnittstellenFolderId;
      });
    },
    checkStep(){
      this.$store.dispatch("loading/toggle",true);
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep(this.form).then(() => {
        window.scrollTo(0,0);
        this.$emit("next");
      })
    }
  }
}
</script>

<style scoped>

</style>