<template>
  <v-container>
    <VideoWrapper :thumbnail="require('@/assets/onboardingvideos/Thumbnail2.png')" title="Infos zu Ihrer Marke und Ihrem Unternehmen" :video-url="require('@/assets/onboardingvideos/Teil 2.webm')"></VideoWrapper>


    <v-form
        class="my-5"
        ref="form"
        v-model="valid"
    >
      <v-text-field filled rounded type="text" label="Was ist Ihr offizieller Firmenname?" placeholder="XY GmbH" hint="z.B. 'XY GmbH' oder im Fall eines Einzelunternehmers 'Max Mustermann'" v-model="form.companyName" />

      <FormSubheader title="Eigenschaften"/>

      <InputGroup title="Welche 3 Adjektive beschreiben die wichtigsten Charaktereigenschaften Ihrer Marke?">
        <v-text-field class="mt-1" hide-details filled rounded v-model="form.adjective1" placeholder="Adjektiv 1..."></v-text-field>
        <v-text-field class="mt-1" hide-details filled rounded v-model="form.adjective2" placeholder="Adjektiv 2..."></v-text-field>
        <v-text-field class="mt-1" hide-details filled rounded v-model="form.adjective3" placeholder="Adjektiv 3..."></v-text-field>

      </InputGroup>
      <InputGroup title="Welche Werte, Stile oder Charakteristika treffen auf Ihr Wunschdesign am ehesten zu?">
      <v-chip-group v-model="form.styles" multiple column active-class="primary">
        <v-chip v-for="style of styles" :value="style" :input-value="style" :key="style"  >{{style}}</v-chip>
      </v-chip-group>


      </InputGroup>

      <InputGroup title="Nicht - Wünsche">
      <v-textarea class="mt-1" hide-details filled rounded v-model="form.donts" placeholder="Beschreiben Sie explizit, was Sie sich für Ihre Seite NICHT wünschen."></v-textarea>
      </InputGroup>

      <FormSubheader title="Farben & Schriften"/>

        <v-switch v-model="form.newBranding" label="Elephant soll bitte das Branding der neuen Seite (Farben, Schriftarten) übernehmen."></v-switch>

        <InputGroup v-if="form.newBranding" title="Haben Sie schon festgelegte Farben oder Schriftarten für Ihre Marke?">
          <v-textarea filled rounded label="Farben" v-model="form.colors" hint="Bitte trennen Sie Ihre Farben mit Komma." placeholder="z.B. Brandfarbe1: #3839, Brandfarbe2:#24354, Accentfarbe: #8978, textfarbe:#89790"></v-textarea>
          <v-textarea filled rounded label="Schriften" v-model="form.font" placeholder="Schriften mit , getrennt, z.B. Arial, Times"></v-textarea>
        </InputGroup>

      <InputGroup title="Bitte geben Sie Links zu 2-3 Referenz-Websites an, an denen sich Elephant beim Design Ihrer neuen Website orientieren kann.">
        <v-textarea  type="textarea"
                     filled rounded
                     v-model="form.referenceSites"
                     hint="Beschreiben Sie für jeden Referenz-Website-Link kurz, was Ihnen daran gut gefällt."
                     placeholder="z.B. https://www.google.de - CTA Button sehr gut gelungen"
        ></v-textarea>
      </InputGroup>

      <InputGroup title="Bitte geben Ihre Kontaktemailadresse an, über die Ihre Kunden Sie erreichen können.">
        <v-text-field filled rounded type="email"
                      v-model="form.contactEmail"
                      label=""
                      placeholder="z.B. info@elephant-agency.de"></v-text-field>

      </InputGroup>

      <div v-if="!recap" class="mx-5 my-5 d-flex justify-space-between">
        <v-btn @click="stepBack" text>Zurück</v-btn>
        <v-btn @click="saveStepData" text>Zwischenspeichern</v-btn>
        <v-btn color="primary" :loading="$store.state.loading.loadingState" @click="checkStep">Nächster Schritt</v-btn>
      </div>

    </v-form>

  </v-container>
</template>

<script>
import {nextStep, getOnboardingData, saveData,getBack} from "@/services/onboarding.service";
import InputGroup from "@/views/Prozesse/OnBoarding/Steps/InputGroup";
import FormSubheader from "@/views/Prozesse/OnBoarding/Steps/FormSubheader";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";

export default {
  name: "Two",
  components: {VideoWrapper, FormSubheader, InputGroup},
  data(){
    return{
      valid:false,
      styles:[
        "klar und übersichtlich",
        "minimalistisch und leicht",
        "geradlinig und bodenständig",
        "seriös und vertrauensvoll",
        "verspielt und fröhlich",
        "extravagant und ausgefallen",
        "dezent, elegant und hochwertig",
        "edel und teuer",
        "stark und dynamisch",
        "persönlich und emotional",
        "business like",
        "retro",
        "zeitlos",
        "modern und trendig"
      ],

    }

  },
  props:{
    recap:Boolean
  },
  computed:{
    form(){
      return this.$store.state.processes.onboarding.data;
    }
  },
  created() {
    getOnboardingData();
  },
  methods:{
    stepBack(){
      getBack();
    },
    saveStepData(){
      saveData(this.$store.state.processes.onboarding.data);
    },
    back(){
      //TODO Implement back frontend
    },
    checkStep(){
      //TODO Go to Server and check step 1 if good increase activeStep
      this.$store.dispatch("loading/toggle",true);
      nextStep(this.form).then(() => {
        window.scrollTo(0,0);
        this.$emit("next");
      })
    }
  }
}
</script>

<style scoped>

</style>