<template>
<v-container>
  <v-progress-circular v-if="!login" size="100px" indeterminate></v-progress-circular>
  <v-dialog
      v-model="login"
      persistent
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Willkommen zurück!</span>
        <p class="caption">
          Wir haben uns selbstverständlich gemerkt, dass Sie bereits ein Projekt bei uns hatten. <br/> Bitte loggen Sie sich daher mit Ihren Accountdaten an.
        </p>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
            >
              <v-text-field
                  label="Email"
                  required
                  hide-details
                  rounded filled
                  v-model="email"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
            >
              <v-text-field
                  rounded filled
                  type="password"
                  v-model="password"
                  label="Passwort"
                  @keypress.enter="handleLogin"
                  ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>

        <v-btn
            block
            color="primary"
            @click="handleLogin"
            class="rounded-elephant"
        >
          Jetzt einloggen.
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import ApiService from "@/services/api.service"
import {NotifiyClient} from "@/models/notification";

export default {
  name: "Start",
  data(){
    return{
      login:false
    }
  },
  props:{
    user:String,
    email:String
  },
  created() {
    if(!this.user){
      console.log("Please login to continue");
      this.login=true;
    }else{
    if(!this.$store.state.auth.status.loggedIn) {
      this.handleAutoLogin();
    }else{
      ApiService.post("onboarding/step/check",{id:this.$store.state.processes.onboarding.instanceId}).then(response => {
        console.log("Next STep",response.data);
        this.$emit("next");
      })
    }
    }
  },
  methods: {
    handleLogin(){
      this.$store.dispatch("loading/toggle", true);
      /**
       this.$validator.validateAll().then(isValid => {
                    if (!isValid) {
                        this.loading = false;
                        return;
                    }
       **/
      if (this.email && this.password) {
        this.$store.dispatch('auth/login', {email:this.email,password:this.password}).then(
            () => {
              this.$store.dispatch("loading/success");
              ApiService.post("onboarding/step/check",{id:this.$store.state.processes.onboarding.instanceId}).then(response => {
                console.log("Next STep",response.data);
                this.$emit("next");
              })

              console.log("Login successfull");
            },
            () => {
              this.$store.dispatch("loading/error")

              this.$store.dispatch("notificationsStore/add", new NotifiyClient("error", "Error", false, "Login fehlgeschlagen."))

            }
        );
      }
    },
    handleAutoLogin() {
      this.$store.dispatch("loading/toggle", true);

      if (this.email && this.user) {
        console.log("Email",this.email);
        console.log("Passwort",this.user);
        this.$store.dispatch('auth/login', {email: this.email, password: this.user}).then(
            () => {
              this.$store.dispatch("loading/success");
              ApiService.post("onboarding/step/check",{id:this.$store.state.processes.onboarding.instanceId}).then(response => {
                console.log("Next STep",response.data);
                this.$emit("next");
              })

              console.log("Login successfull");
            },
            () => {
              this.$store.dispatch("loading/error")
              this.$store.dispatch("notificationsStore/add", new NotifiyClient("error", "Error", false, "Please try again."))
            }
        );
      }
    }
  }
}
</script>

<style scoped>

</style>