<template>
  <v-timeline-item
      color="pink"
      small
  >
    <v-row class="pt-1">
      <v-col cols="3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">

        <strong v-on="on" v-bind="attrs">
          <span v-html="computedTimestamp"></span></strong>
          </template>
        {{computedTimestampLong}}
        </v-tooltip>
      </v-col>
      <v-col>
        <strong v-html="updateItem.title"></strong>
        <div v-html="updateItem.content" class="text-caption">

        </div>
      </v-col>
      <v-col v-if="isAdmin">
        <v-btn @click="deleteUpdate" icon x-small><v-icon>mdi-delete</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>

<script>
import moment from 'moment';
import ApiService from "@/services/api.service";
export default {
  name: "UpdateItem",
  props:{
    updateItem:Object,
    projectId:Number
  },
  methods:{
    deleteUpdate(){
      let self=this;
      ApiService.post("project/update/delete",{projectId:this.projectId,updateItem:this.updateItem}).then(response =>{
        console.log("Porject ",response.data)
        self.$emit("updateProject",response.data);
      })
    }
  },
  computed:{
    isAdmin(){
      return this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
    },
    computedTimestamp(){
      moment().locale("de");
      return moment(this.updateItem.timestamp).format("MMM");
    },
    computedTimestampLong(){
      moment.locale("de");
      return moment(this.updateItem.timestamp).format("DD MMM YYYY HH:mm")
    }
  }
}
</script>

<style scoped>

</style>