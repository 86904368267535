<template>
  <v-container>
    <VideoWrapper :thumbnail="require('@/assets/onboardingvideos/Thumbnail6.png')" :video-url="require('@/assets/onboardingvideos/Teil 6.mp4')" title="Erwartungshaltung: Kommunikation und Ergebnisse">

    </VideoWrapper>

    <div v-if="!recap" style="position: absolute;bottom:0;width:100%;left:0;" class="px-5 my-5 d-flex justify-space-between">
      <v-btn text @click="stepBack()">Zurück</v-btn><v-btn color="primary" @click="checkStep">Nächster Schritt</v-btn>
    </div>

  </v-container>
</template>

<script>
import {getBack, nextStep} from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";

export default {
  name: "Six",
  components: {VideoWrapper},
  props:{
    recap:Boolean
  },
  methods:{
    stepBack(){
      getBack();
    },
    checkStep(){
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep().then(() => {
        window.scrollTo(0,0);

        this.$emit("next");
      })
    }
  }
}
</script>

<style scoped>

</style>