<template>
<v-container>
  <v-list color="transparent">
    <v-list-item @click="$router.push('admin/onboardings')">
      <v-list-item-content>
        <v-list-item-title>
          Onboardingprozesse
        </v-list-item-title>
        <v-list-item-subtitle>
          Eine Liste aller laufenden Prozesse + die Möglichkeit neue zu erstellen.
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-icon>
    </v-list-item>
    <v-list-item @click="$router.push('admin/projekte')">
      <v-list-item-content>
        <v-list-item-title>
          Projekte
        </v-list-item-title>
        <v-list-item-subtitle>
          Eine Liste aller Projekte.
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</v-container>
</template>

<script>
export default {
  name: "AdminHome",
}
</script>

<style scoped>

</style>