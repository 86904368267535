<template>
  <div>
<ProjectItem v-if="$store.state.project.selected" :projectData="$store.state.project.selected"/>
    <div v-else class="caption">
      <p v-if="$store.state.project?.projects?.length<0 &! $store.state.loading.loadingState" class="mx-auto">Sie haben keine Projekte</p>

    </div>
  </div>
</template>

<script>
import ProjectItem from "@/views/Projekte/ProjectItem";
export default {
  name: "ProjectWrapper",
  components: {ProjectItem},
  created() {
    this.$store.dispatch("project/getMyProjects");
  }
}
</script>

<style scoped>

</style>