<template>

  <v-container>
<v-list v-if="$store.state.project.projects">
<v-list-item v-for="project of $store.state.project.projects" :key="project.id" @click="$router.push('/admin/projekte/'+project.id)">
  <v-list-item-avatar>
    <v-progress-circular :value="project.progress*100">

    </v-progress-circular>
  </v-list-item-avatar>
  <v-list-item-content>
    <v-list-item-title v-html="project.name">

    </v-list-item-title>
    <v-list-item-subtitle v-html="'id: '+project.id">

    </v-list-item-subtitle>
  </v-list-item-content>
  <v-list-item-icon>
    <v-icon>mdi-chevron-right</v-icon>
  </v-list-item-icon>
</v-list-item>
</v-list>
  </v-container>
</template>

<script>
export default {
  name: "AdminProjectList",
  created(){
    this.$store.dispatch("project/getAllProjects");
  }
}
</script>

<style scoped>

</style>