<template>
<v-list-item style="max-width: 100%" @click="open" three-line v-if="project">
  <v-list-item-avatar>
    <v-progress-circular v-if="onboarding.activeStep<7" :value="onboarding.activeStep/7*100">

    </v-progress-circular>
    <v-progress-circular v-else color="success" :value="onboarding.activeStep/7*100">

    </v-progress-circular>
  </v-list-item-avatar>
  <v-list-item-content>
    <v-list-item-title class="text-truncate"><span v-html="project.name"></span> - <span class=""><span v-if="onboarding.activeStep>=7">7</span><span v-else>{{onboarding.activeStep}}</span>/7</span></v-list-item-title>
    <v-list-item-subtitle class="text-truncate"><span>ID </span><span v-html="onboarding.id"></span></v-list-item-subtitle>
  </v-list-item-content>
  <v-list-item-icon v-if="admin">
    <v-btn icon color="red" @click="deleteOnboarding"><v-icon>mdi-delete</v-icon></v-btn>
  </v-list-item-icon>
</v-list-item>
</template>

<script>
import ApiService from '@/services/api.service';
import {NotifiyClient} from "@/models/notification";
export default {
  name: "OnboardingItem",
  props:{
    onboarding:Object,
    admin:Boolean
  },
  data(){
    return{
      project:null
    }
  },
  watch:{
  },
  created() {
    this.getProjectOfInstance();
    },
  methods:{
    deleteOnboarding(){
      let self=this;
      ApiService.post("onboarding/delete",{id: this.onboarding.id}).then(response => {
        if(response.data){
          self.$store.dispatch("notificationsStore/add",new NotifiyClient("success","Erfolgreich gelöscht",false));
        }
      })
    },
    open(){
      this.$router.push({path: "/processes/onboarding/"+this.onboarding.id});
    },
    getProjectOfInstance(){
      let self=this;
      console.log("Instance ID",this.onboarding.id);
      ApiService.post("onboarding/project/get",{id:this.onboarding.id}).then(response => {
        self.project=response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>