import Vue from 'vue'
import App from './App.vue'
import { router } from './router';
import store from './store';
import VeeValidate from 'vee-validate';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify.js' // path to vuetify.js export
import "./scss/global.scss"
import VueI18n from 'vue-i18n';
import messages from './plugins/VueI18n';
import "hover.css";
import 'vue-advanced-cropper/dist/style.css';
import { Cropper } from 'vue-advanced-cropper';
import { Vue2InteractDraggable } from 'vue2-interact'
import "@/registerServiceWorker";
Vue.use(Vue2InteractDraggable);

import VueConfetti from 'vue-confetti'

import VueCalendly from 'vue-calendly';

Vue.use(VueCalendly);

Vue.use(VueConfetti)


Vue.use(Cropper);


import "spotify-web-api-node";

//Spotify
var SpotifyWebApi = require('spotify-web-api-node');


// credentials are optional
var spotifyApi = new SpotifyWebApi();
Vue.prototype.$spotifyApi=spotifyApi;
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(VeeValidate);
Vue.config.productionTip = false

Vue.prototype.$host = "http://localhost:8080"



const i18n = new VueI18n({
  locale: 'de',
  messages
});


new Vue({
  render: h => h(App),
  router,
  vuetify,
  i18n,
  store,
  }).$mount('#app')
