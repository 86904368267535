<template>
  <v-container>
    <VideoWrapper :thumbnail="require('@/assets/onboardingvideos/Thumbnail5.png')" title="Zielgruppe & Angebot" :video-url="require('@/assets/onboardingvideos/Teil 5.webm')"/>

    <v-form
        class="my-5 mx-2"
        ref="form"
        v-model="valid"
    >
      <FormSubheader title="Branche"></FormSubheader>
      <InputGroup title="Beschreiben Sie Ihre Branche.">
        <v-textarea hide-details filled rounded v-model="form.brancheBeschreibung"/>
      </InputGroup>
      <InputGroup title="Rolle Ihres Unternehmens in dieser Branche">
        <v-textarea hide-details filled rounded placeholder="Beschreiben Sie die Rolle Ihres Unternehmens in oben beschriebener Branche." v-model="form.brancheRolle"/>
      </InputGroup>
      <FormSubheader title="Auflistung Ihres Angebots"></FormSubheader>
      <InputGroup>
        <v-textarea class="mt-1" hide-details filled rounded placeholder="Welche Produkte/Dienstleistungen werden angeboten?" v-model="form.produkteDienstleistungen"/>
        <v-textarea class="mt-1" hide-details filled rounded label="Was unterscheidet Sie von der Konkurrenz?" v-model="form.unterscheidenKonkurrenz"/>
       <!-- <v-textarea class="mt-1" hide-details filled rounded label="Was sind allgemeine USPs?" v-model="form.usps"/>-->

      </InputGroup>
      <FormSubheader title="Beschreibung der Zielgruppe"></FormSubheader>
      <InputGroup>

        <v-textarea class="mt-1"  hide-details filled rounded placeholder="Beschreibe Sie Ihre Zielgruppe" v-model="form.wunschLoesungAngebot"/>
        <v-textarea class="mt-1"  hide-details filled rounded placeholder="Was wünscht sich Ihre Zieldgruppe von Ihrer Lösung/ Angebot" v-model="form.argumente"/>
        <v-textarea class="mt-1" hide-details filled rounded placeholder="Wie lässt sich Ihre Zielgruppe überzeugen? Was sind treffende Argumente?" v-model="form.sonstigesZielgruppe"/>

      </InputGroup>
      <FormSubheader title="Ziel & Kunden"></FormSubheader>
      <InputGroup>
      <v-textarea class="mt-1" hide-details filled rounded placeholder="Was ist das Ziel der Website? (z.B. Neuen Kunden / Neue Bewerber / Direktverkäufe)" v-model="form.websiteZiel"/>
      <v-textarea class="mt-1" hide-details filled rounded placeholder="Was ist der primäre CTA (Call to Action) der Website" hint="Geben Sie, wenn möglich, ein Beispiel an." v-model="form.cta"/>
      <v-textarea class="mt-1" hide-details filled rounded placeholder="Was waren bisher typische Kunden Ihres Unternehmens?" v-model="form.typischeKunden"/>
      <v-textarea class="mt-1" hide-details filled rounded placeholder="Wie würden Sie Ihren Traumkunden beschreiben?" v-model="form.traumkunde"/>
      <!--<v-textarea class="mt-1" hide-details filled rounded placeholder="Was sind Probleme & Wünsche Ihrer Traumkunden?" v-model="form.problemeWuenscheTraumkunde"/>
      <v-textarea class="mt-1" hide-details filled rounded placeholder="Womit beschäftigen sich Ihre Traumkunden? Wovor haben Sie Angst?" v-model="form.gedankenTraumkunde"/>
      -->
      <v-textarea class="mt-1" hide-details filled rounded placeholder="Auf welche Fragen suchen diese Antworten?" v-model="form.fragen"/>
      <!--<v-textarea class="mt-1" hide-details filled rounded placeholder="Wie können wir diesen Leuten Informationen (Website-Texte oder Artikel) weiterhelfen?" v-model="form.informationenWeiterhelfen"/>
        -->
        <v-textarea class="mt-1" hide-details filled rounded placeholder="Was wünschen sich Ihre Traumkunden im Erstkontakt? (Eine neutrale Beratung? Ein unverbvindliches Angebot?)" v-model="form.wuenscheTraumkundeErstkontakt"/>
       <!-- <FormSubheader title="Angebot"></FormSubheader>
        <v-textarea class="mt-1" hide-details filled rounded placeholder="Was macht Ihr Angebot gut und besonders?" v-model="form.vorteileAngebot"/>
        <v-textarea class="mt-1" hide-details filled rounded placeholder="Warum ist Ihr Angebot perfekt passend für Ihre Traumkunden?" v-model="form.angebotPassendTraumkunde"/>
        <v-textarea class="mt-1" hide-details filled rounded placeholder="Was sind Verkaufsargumente, die bei Ihrer Zielgruppe in der Vergangenheit schon gut funktioniert haben?" v-model="form.verkaufsargumente"/>
-->
      </InputGroup>

      <div v-if="!recap" class="mx-5 my-5 d-flex justify-space-between">
        <v-btn @click="stepBack" text>Zurück</v-btn>
        <v-btn @click="saveStepData" text>Zwischenspeichern</v-btn>
        <v-btn color="primary" :disabled="!valid" :loading="$store.state.loading.loadingState" @click="checkStep">Nächster Schritt</v-btn>
      </div>
      <div class="mx-5 d-flex align-center align-content-center" v-if="$store.state.loading.loadingState"><v-progress-circular size="20" indeterminate>
      </v-progress-circular>
        <small class="ml-2">Wir erstellen nun intern das Projekt. Dies kann bis zu 20sec dauern.</small></div>


    </v-form>
  </v-container>
</template>

<script>
//TODO FINISH Fields
import {getBack, getOnboardingData, nextStep, saveData} from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";
import InputGroup from "@/views/Prozesse/OnBoarding/Steps/InputGroup";
import FormSubheader from "@/views/Prozesse/OnBoarding/Steps/FormSubheader";

export default {
  name: "Five",
  components: {FormSubheader, InputGroup, VideoWrapper},
  props:{
    recap:Boolean
  },
  data(){
    return{
      valid:false
    }
  },
  computed:{
    form(){
      return this.$store.state.processes.onboarding.data;
    }
  },
  created() {
    getOnboardingData();
  },
  methods:{
    stepBack(){
      getBack();
    },
    saveStepData(){
      saveData(this.$store.state.processes.onboarding.data);
    },
    checkStep(){
      this.$store.dispatch("loading/toggle",true);
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep(this.form).then(() => {
        window.scrollTo(0,0);
        this.$emit("next");
      })
    }
  }
}
</script>

<style scoped>

</style>