<template>
<v-layout fill-height>
  <v-list style="max-width: 100%">
  <OnboardingItem admin v-for="onboarding of list" :key="onboarding.id" :onboarding="onboarding"></OnboardingItem>
  <CreateOnboarding style="position: sticky;bottom:5px;right:5px;"/>
  </v-list>
</v-layout>
</template>

<script>
import ApiService from "@/services/api.service";
import CreateOnboarding from "@/views/Admin/CreateOnboarding";
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem";
export default {
  name: "AdminOnboardingList",
  components: {OnboardingItem, CreateOnboarding},
  data(){
    return{
    list:[]
    }
  },
  created() {
    this.getOnboardings();
  },
  methods:{
    getOnboardings(){
      let self=this;
      ApiService.get("onboarding/getAll").then(response => {
        self.list=response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>