<template>

  <v-card color="transparent" flat class=" mx-auto px-5 pt-15 pt-md-10">
    <div>
      <v-img class="mx-auto" v-if="!$vuetify.theme.dark" height="100px" width="100px" :src="require('../assets/ElephantNew.png')"></v-img>
      <v-img class="mx-auto" v-else height="100px" width="100px" :src="require('../assets/ElephantNew.png')"></v-img>
    <v-card-title class="d-flex justify-center"><span style="width:100%" class="text-center">Willkommen im</span> <br/>
    <b class="text-center font-weight-bold">Elephant Kundenportal</b></v-card-title>

    <v-form ref="form" class="mx-10 d-flex justify-center flex-wrap" v-model="valid">
      <v-text-field
          solo
          rounded
          autofocus
          style="width:200px"
          label="Email Address"
          type="email"
          v-model="user.email"
          required
      />
      <v-text-field label="Password"
                    v-model="user.password"
                    style="width:100%"
                    solo
                    rounded
                    @keypress.enter="handleLogin"
                    :rules="passwordRules"
                    type="password"
                    required
      />

      <v-btn class="mx-auto align-self-center" :loading="loading" width="100px" :disabled="!valid" @click="handleLogin">
        <span>Login</span>
      </v-btn>
      <v-alert type="error" class="ma-3" v-if="message" dismissible color="red">{{message}}</v-alert>
    </v-form>
     </div>

  </v-card>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {

      valid: true,
      user: {
        email:'',
        password:''
      },
      message: '',
      loading:false,
      username:'',
      usernameRules:[
        v => !!v || this.$t('login.nameRequired'),
        v => (v && v.length <= 20) || this.$t('login.nameLength'),
      ],
      password:'',
      confirmPassword:"",
      passwordRules:
          [
            v => !!v || this.$t('login.passwordRequired'),

          ]

    };
  },
  computed: {
    frontendhost(){
      return process.env.VUE_APP_FRONTHOST;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    //console.log("Der COde ist: "+this.$route.query.code);
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {

    handleLogin() {
      this.loading = true;
      /**
       this.$validator.validateAll().then(isValid => {
                    if (!isValid) {
                        this.loading = false;
                        return;
                    }
       **/
      if (this.user.email && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/profile');
            },
            () => {
              this.loading = false;
              this.message = "You entered the wrong email or password"
            }
        );
      }

    }
  }
};
</script>
<style>

</style>