<template>
<v-container>

  <VideoWrapper :thumbnail="require('@/assets/onboardingvideos/Thumbnail3.png')" title="Zugänge & Social Media" :video-url="require('@/assets/onboardingvideos/Teil 3.webm')"></VideoWrapper>


  <v-form
      class="my-5 mx-2"
      ref="form"
      v-model="valid"
  >
    <FormSubheader title="Socials"/>
    <InputGroup title="Bitte geben Sie hier Ihre Social Media Kanäle ein Name/URL, sodass wir diese in der Website einbauen können.">

      <v-text-field class="mt-1" hide-details filled rounded v-model="form.instagram" label="Instagram Account"></v-text-field>
      <v-text-field class="mt-1" hide-details filled rounded v-model="form.facebook" label="Facebook Account"></v-text-field>
      <v-text-field class="mt-1" hide-details filled rounded v-model="form.twitter" label="Twitter Account"></v-text-field>
      <v-text-field class="mt-1" hide-details filled rounded v-model="form.youtube" label="Youtube Account"></v-text-field>
      <v-text-field class="mt-1" hide-details filled rounded v-model="form.linkedIn" label="LinkedIn Account"></v-text-field>
      <v-text-field class="mt-1" hide-details filled rounded v-model="form.extraSocial" label="Weitere Accounts"></v-text-field>

    </InputGroup>

    <div v-if="!recap" class="mx-5 my-5 d-flex justify-space-between">
      <v-btn text @click="stepBack">Zurück</v-btn>
      <v-btn @click="saveStepData" text>Zwischenspeichern</v-btn>
      <v-btn color="primary" :disabled="!valid" :loading="$store.state.loading.loadingState" @click="checkStep">Nächster Schritt</v-btn>
    </div>
  </v-form>

</v-container>
</template>

<script>
import {getBack, getOnboardingData, nextStep, saveData} from "@/services/onboarding.service";
import VideoWrapper from "@/views/Prozesse/OnBoarding/Steps/VideoWrapper";
import InputGroup from "@/views/Prozesse/OnBoarding/Steps/InputGroup";
import FormSubheader from "@/views/Prozesse/OnBoarding/Steps/FormSubheader";
export default {

  //TODO Get OnBoarding Data on create so old Data is there
  name: "Three",
  components:{FormSubheader, InputGroup, VideoWrapper},
  data() {
    return {
      valid:false,

    }
  },
  props:{
    recap:Boolean
  },
  computed:{
    form(){
      let form=this.$store.state.processes.onboarding.data;
      if(form) {
        if (!form.hosting) {
          form.hosting = ["", "", ""];
        }
        if (!form.hosting[0]) {
          form.hosting[0] = "";
        }
        if (!form.hosting[1]) {
          form.hosting[1] = "";
        }
        if (!form.hosting[2]) {
          form.hosting[2] = "";
        }

        if (!form.oldSite) {
          form.oldSite = ["", "", ""];
        }
        if (!form.oldSite[0]) {
          form.oldSite[0] = "";
        }
        if (!form.oldSite[1]) {
          form.oldSite[1] = "";
        }
        if (!form.oldSite[2]) {
          form.oldSite[2] = "";
        }
      }
      return form;
    }
  },
  created() {
    getOnboardingData();
  },
  methods:{
    stepBack(){
      getBack();
    },
    saveStepData(){
      saveData(this.$store.state.processes.onboarding.data);
    },
    checkStep(){
      this.$store.dispatch("loading/toggle",true);
      //TODO Go to Server and check step 1 if good increase activeStep
      nextStep(this.form).then(() => {
        window.scrollTo(0,0);
        this.$emit("next");
      })
    }
  }
}
</script>

<style scoped>

</style>