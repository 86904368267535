<template>
<v-dialog scrollable v-model="show">

  <template v-slot:activator="{ on, attrs }">
    <v-btn fab
           style="position: absolute;bottom:10px;right:10px"
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">Erstelle neues Onboarding.</v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: 80vh">
  <v-layout  class="px-4 py-2" wrap>
  <v-flex xs6>
    <v-text-field filled rounded v-model="onboardingRequest.customerName" label="Vorname Kunde"></v-text-field>

  </v-flex>
    <v-flex xs6>
      <v-text-field filled rounded v-model="onboardingRequest.customerLastname" label="Nachname Kunde"></v-text-field>

    </v-flex>
      <v-text-field type="email" filled rounded v-model="onboardingRequest.customerEmail" label="Kundenemail"></v-text-field>
  <v-text-field filled rounded v-model="onboardingRequest.projectName" label="Projektname"></v-text-field>
    <v-select filled rounded :items="users" item-value="id" item-text="firstName" v-model="onboardingRequest.salesContact" label="Sales Ansprechpartner"></v-select>
    <v-select filled rounded :items="users" item-value="id" item-text="firstName" v-model="onboardingRequest.projectContact" label="Projekt Ansprechpartner"></v-select>

    <v-switch v-model="onboardingRequest.manuelCreationOfFolders" label="Kundenordner ist bereits erstellt."></v-switch>
    <v-text-field v-if="onboardingRequest.manuelCreationOfFolders" hint="Diesen findet man im Addressfeld, wenn man den Ordner im Drive öffnet" filled rounded v-model="onboardingRequest.schnittstellenFolderId" label="Id der Schnittstelle"></v-text-field>
    <v-text-field v-if="onboardingRequest.manuelCreationOfFolders" hint="Diesen findet man im Addressfeld, wenn man den Ordner im Drive öffnet" filled rounded v-model="onboardingRequest.folderId" label="Id des Kundenornders"></v-text-field>

  </v-layout>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text @click="show=false">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="$store.state.loading.loadingState" @click="createOnboarding">Create</v-btn>

    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
//TODO switch if projectfiles should be created automatically
//If not provide textfield for Schnittstelle usw.
import ApiService from "@/services/api.service"
import {NotifiyClient} from "@/models/notification";
export default {
  name: "CreateOnboarding",
  data(){
    return{
      show:false,
      users:[],
      createCustomerFolderManual:false,
      onboardingRequest:{
        projectName:"",
        customerName:"",
        customerLastname:"",
        customerEmail:"",
        salesContact:"",
        projectContact:"",
        manuelCreationOfFolders:false,
        schnittstellenFolderId:"",
        folderId:""

      }
    }
  },
  created() {
    this.getAllUsers();
  },
  methods:{
    connectGoogle(){
      ApiService.get("onboarding/connectGoogle")
    },
    createOnboarding(){
     let self=this;
      this.$store.dispatch("loading/toggle",true);
      ApiService.post("onboarding/create",this.onboardingRequest).then((response) => {
      if(response.status===200){
        self.$store.dispatch("loading/success");
        self.$store.dispatch("notificationsStore/add",new NotifiyClient("success","Onboarding gestartet.",false,"Das Onboarding "+response.data.id+" wurde gestartet. & Kunde wurde Benachrichtigt."));
      }else{
        self.$store.dispatch("loading/error");
        self.$store.dispatch("notificationsStore/add",new NotifiyClient("error","Fehlgeschlagen.",false,"Das Onboarding konnte nicht erstellt werden."));

      }
      });
    },
    getAllUsers(){
      let self=this;
      ApiService.get("user/getAll").then(response => {
        self.users=response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>