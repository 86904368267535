import axios from 'axios';
import authHeader from './auth-header';
import ApiService from "@/services/api.service";
import store from "@/store";

const API_URL = 'http://localhost:8080/api/test/';

export function updateUser(newUser){
    ApiService.post("user/update",newUser).then(response => {
        let user =JSON.parse(localStorage.getItem("user"));
        let merged = {...user, ...response.data};
        store.dispatch("auth/updateLocalUser",merged);
        store.dispatch("loading/success");
    });
}

export function changePassword(newP){
    ApiService.post("user/changePassword",{oldPassword:"", newPassword:newP}).then(response => {
        updateUser(response.data)
    })
}

class UserService {
    getPublicContent() {
        return axios.get(API_URL + 'all');
    }

    getUserBoard() {
        return axios.get(API_URL + 'user', { headers: authHeader() });
    }

    getModeratorBoard() {
        return axios.get(API_URL + 'mod', { headers: authHeader() });
    }

    getAdminBoard() {
        return axios.get(API_URL + 'admin', { headers: authHeader() });
    }
}

export default new UserService();