<template>
<div>

      <v-container>
       <v-layout class="py-5 px-5" wrap>

         <v-flex xs12>
           <v-progress-linear
               dark

               :class="{'disabled-progress':!isAdmin}"
               @change="saveProject"
               class="mb-5 px-2"
               rounded
               v-model="projectData.progress"
               color="accent"
               height="40"
           >
             <template v-slot:default="{ value }">
               <strong style="font-size:0.8rem">Gesamtprojekt zu {{Math.ceil(value)}}% abgeschlossen</strong>
             </template>
           </v-progress-linear>
           <div style="max-height: 400px;overflow-y: scroll;overflow-x: hidden"
           >
           <v-timeline
               align-top
               dense
               class="mb-5"
           >
            <UpdateNew v-if="isAdmin" @updateProject="updateProject" :project-id="projectData.id"/>
             <UpdateItem v-for="item of sortedUpdates" :key="item.id" @updateProject="updateProject" :update-item="item" :project-id="projectData.id"/>
           </v-timeline>
           </div>
         </v-flex>
         <v-flex class="pa-5 mt-10" xs12 md4>
           <v-sheet class="pa-5" color="#f5f5f5" rounded elevation="1">
             <h4>Ansprechpartner</h4>
             <br/>
             <ContactItem class="mt-5 mt-md-5" :userId="projectData.projectContact" over-headline="Projektabwicklung"></ContactItem>

           </v-sheet>
           </v-flex>
         <v-flex class="pa-5 mt-10" xs12 md4>
           <v-sheet class="pa-5" color="#f5f5f5" rounded elevation="1">
             <h4>Projekt</h4>
             <br/>
              <v-sheet rounded class="pa-3  mb-4 d-flex justify-space-between">
                <h5>vrstl. Projektabschluss</h5> <span style="font-size:0.8rem;"><span v-text="projektabschluss"></span><v-btn @click="changeEstimatedProjectEnd=!changeEstimatedProjectEnd" small icon v-if="isAdmin"><v-icon>mdi-pencil</v-icon></v-btn></span>
              </v-sheet>
              <v-sheet class="pa-3" rounded>
                <h5>Beschreibung<v-btn @click="changeDescription=!changeDescription" small icon v-if="isAdmin"><v-icon>mdi-pencil</v-icon></v-btn></h5>
                <br/>
                <div style="font-size:0.8rem;" v-html="projectData.description"></div>
              </v-sheet>

           </v-sheet>
         </v-flex>
         <v-flex class="pa-5 mt-10" xs12 md4>
           <v-sheet class="pa-5" color="#f5f5f5" rounded elevation="1">
             <h4>Onboarding</h4>
             <br/>
             <OnboardingItem v-if="$store.state.project.onboarding" :onboarding="$store.state.project.onboarding"/>

           </v-sheet>
         </v-flex>

       </v-layout>
      </v-container>
      <v-dialog width="auto" v-model="changeEstimatedProjectEnd">
        <v-card>

            <v-date-picker v-model="projectData.estimatedProjectEnd"></v-date-picker>

          <v-card-actions>
            <v-btn text block @click="saveProject">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog width="500px" v-model="changeDescription">
        <v-card>

            <v-textarea solo v-model="projectData.description"></v-textarea>

          <v-card-actions>
            <v-btn text block @click="saveProject">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
     </div>
</template>

<script>
import ContactItem from "@/views/Projekte/ContactItem";
import OnboardingItem from "@/views/Prozesse/OnBoarding/OnboardingItem";
import UpdateItem from "@/views/Projekte/UpdateItem";
import UpdateNew from "@/views/Projekte/UpdateNew";
import ApiService from "@/services/api.service";
export default {
  name: "ProjectItem",
  components: {UpdateNew, UpdateItem, OnboardingItem, ContactItem},
  props:{
    projectData:Object,
    id:String
  },
  data(){
    return{
      changeDescription:false,
      changeEstimatedProjectEnd:false,
      tabs:{
        selected:null,
        items:["Info","Prozesse","Serviceanfragen"]
      },
      ansprechpartner:{
        sales:null,
        project:null
      }
    }
  },
  computed:{
    projektabschluss(){
      let moment = require('moment');
      moment.locale('de');
      if(!this.projectData.estimatedProjectEnd) return null;
      else{
        return moment(this.projectData.estimatedProjectEnd).format("LL");
      }
    },
    isAdmin(){
      return this.$store.state.auth.user.roles.includes("ROLE_ADMIN");
    },
    sortedUpdates(){
      return [...this.projectData.updateItems].sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
    }
  },
  watch:{
    projectData(){
      if(this.id){
        let self=this;
        ApiService.get("project/get/"+this.id).then(response => {
          self.projectData=response.data;
          this.$store.dispatch("project/getOnboardingOfProject",this.projectData.onboardingId);
        });
      }else{
        this.$store.dispatch("project/getOnboardingOfProject",this.projectData.onboardingId);
      }

    }
  },
  created(){
    if(this.id){
      let self=this;
    ApiService.get("project/get/"+this.id).then(response => {
      self.projectData=response.data;
      this.$store.dispatch("project/getOnboardingOfProject",this.projectData.onboardingId);
    });
    }else{
      this.$store.dispatch("project/getOnboardingOfProject",this.projectData.onboardingId);
    }

  },
  methods: {
    updateProject(project) {
      this.projectData = project;
      this.changeEstimatedProjectEnd=false;
      this.changeDescription=false;
    },
    saveProject() {
      if (this.isAdmin) {
        ApiService.post("project/update", this.projectData).then(response => {
          this.updateProject(response.data);
        });
      }
    },
  }
}
</script>

<style scoped>

</style>