export const app = {
    namespaced: true,
    state: {
        tabs:{
            tabItems:[
                'Laufend',
                'Abgeschlossen'
            ],
            selected:'Laufend'
        },
        welcome:{
            page:1,
            pages:4,
            progress: 1,
        },
        navItems:[
    { title: 'Profil' , target: 'profile' },
    { title: 'Projekte', target: 'my/projects' }
],
        initialHistory:0
    },

}